import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@app/helper';
import { interval, Subscription } from 'rxjs';

@Pipe({
  name: 'sinceDate',
  standalone: true,
  pure: false,
})
export class SinceDatePipe implements PipeTransform, OnDestroy {
  private cdRef = inject(ChangeDetectorRef);

  private timeSince!: string;
  private lastUpdated!: Date;
  private subscription!: Subscription;

  private calcDiff(value: Date | string | number): string {
    if (!value) return 'Unknown';

    const now = new Date();
    const createdAt = new Date(value);
    const seconds = Math.floor((now.getTime() - createdAt.getTime()) / 1000);

    if (seconds < 60) return `${seconds} ${StringUtil.pluralize('second', seconds)} ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} ${StringUtil.pluralize('minute', minutes)} ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} ${StringUtil.pluralize('hour', hours)} ago`;
    const days = Math.floor(hours / 24);
    if (days < 30) return `${days} ${StringUtil.pluralize('day', days)} ago`;
    const months = Math.floor(days / 30);
    if (months < 12) return `${months} ${StringUtil.pluralize('month', months)} ago`;
    const years = Math.floor(days / 365);
    return `${years} ${StringUtil.pluralize('year', years)} ago`;
  }

  transform(value: Date | string | number): string {
    if (!value) return '';

    const date = new Date(value);

    if (!this.lastUpdated || this.lastUpdated.getTime() !== date.getTime()) {
      this.lastUpdated = date;
      this.updateTimeSince(date); // Initial calculation
      this.startAutoUpdate(date);
    }

    return this.timeSince;
  }

  private updateTimeSince(date: Date): void {
    this.timeSince = this.calcDiff(date);
    this.cdRef.markForCheck();
  }

  private startAutoUpdate(date: Date): void {
    this.cleanup();

    this.subscription = interval(60 * 1000).subscribe(() => this.updateTimeSince(date));
  }

  private cleanup(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.cleanup();
  }
}
