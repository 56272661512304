import { Directive, ElementRef, Input, Output, EventEmitter, OnDestroy, AfterViewInit, inject } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map, Subject, takeUntil } from 'rxjs';
import { environment } from '@environment';

@Directive({
  selector: '[appDebounceInput]',
  standalone: true,
})
export class DebounceInputDirective implements AfterViewInit, OnDestroy {
  private el = inject(ElementRef);
  private readonly onDestroy$ = new Subject<void>();

  @Input() debounceTime = environment.UI.DEBOUNCE_TIME;
  @Output() debouncedInput: EventEmitter<string> = new EventEmitter();

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement as HTMLInputElement, 'keyup').pipe(
      map(() => this.el.nativeElement.value),
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    ).subscribe(text => this.debouncedInput.emit(text));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
