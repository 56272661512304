import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@app/helper';

@Pipe({
  name: 'formatStatus',
  standalone: true
})
export class FormatStatusPipe implements PipeTransform {
  transform = (status: string): string => StringUtil.capitalizeAll(status, true);
}
