import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

interface TransferEvent {
  dataTransfer: {
    files: File[];
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[fileDragDrop]',
  standalone: true,
})
export class DragDropDirective {

  @Output() fileDropped = new EventEmitter<File[]>();

  @HostBinding('class.drag-hover') hoverClass = false;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();

    this.hoverClass = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();

    this.hoverClass = false;
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();

    this.hoverClass = false;

    const files = (evt as unknown as TransferEvent).dataTransfer.files as File[];

    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

}
